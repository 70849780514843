import { Injectable } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { DataFetcherService } from "src/app/services/data-fetcher/data-fetcher.service";

@Injectable({
  providedIn: 'root'
})
export class DateHelper {
  constructor() {
  }
  static getDate(day, month, year) {
    return new Date(year, month, 1);
  }
  static getYMD(dt = new Date()) {
    var mnth = ("0" + (dt.getMonth() + 1)).slice(-2)
    var day = ("0" + dt.getDate()).slice(-2)
    return dt.getFullYear() + "-" + mnth + "-" + day;
  }
  static getDMY(dt = new Date()) {
    var mnth = ("0" + (dt.getMonth() + 1)).slice(-2)
    const day = ("0" + dt.getDate()).slice(-2)
    return day + "/" + mnth + "/" + dt.getFullYear();
  }

  static getYMDT(dtndTime = new Date()) {
    var mnth = ("0" + (dtndTime.getMonth() + 1)).slice(-2)
    var day = ("0" + dtndTime.getDate()).slice(-2)
    var hours = ("0" + dtndTime.getHours()).slice(-2)
    var min = ("0" + dtndTime.getMinutes()).slice(-2)
    var strTime = hours + ':' + min
    return dtndTime.getFullYear() + "/" + mnth + "/" + day + " " + strTime
  }

  static getMyTime(tim = new Date()) {
    var hrs = tim.getHours()
    var mini = tim.getMinutes()
    var ampm = hrs >= 12 ? 'PM' : 'AM';
    var hours = ("0" + tim.getHours()).slice(-2)
    var min = ("0" + tim.getMinutes()).slice(-2)
    var strTime = hours + ':' + min + ' ' + ampm;
    return strTime;
  }

  static convertDMYToYMD(dt) {
    var dateSplit = dt.split("/");
    if (dateSplit.length > 2) {
      var day = dateSplit[0];
      var mnth = dateSplit[1];
      var year = dateSplit[2];
      return year + "-" + mnth + "-" + day;
    }
    return "";
  }
  static convertYMDToDMY(dt) {
    if (dt.includes('T')) {
      dt = dt.split('T')[0]
    }
    var dateSplit = dt.split("-");
    if (dateSplit.length > 2) {
      var day = dateSplit[2];
      var mnth = dateSplit[1];
      var year = dateSplit[0];
      return day + "/" + mnth + "/" + year;
    }
    return "";
  }

  static convertYMDToMY(dt) {
    if (dt.includes('T')) {
      dt = dt.split('T')[0]
    }
    var dateSplit = dt.split("-");
    if (dateSplit.length > 2) {
      var mnth = dateSplit[1];
      var year = dateSplit[0];
      return mnth + "/" + year;
    }
    return "";
  }

  static convertYMDToTime(dt) {
    if (dt.includes('T')) {
      dt = dt.split('T')[1]
    }
    var timeObject = dt.split("+")[0];
    var timeSplit = timeObject.split(":")
    if (timeSplit.length > 2) {
      var hr = timeSplit[0];
      var min = timeSplit[1];
      var sec = timeSplit[2];
      return hr + ":" + min;
    }
    return "";
  }

  static convertDatePickerDate(dataObj) {
    if (dataObj.hasOwnProperty("day")) {
      var day = ("0" + (dataObj["day"])).slice(-2);
      var mnth = ("0" + (dataObj["month"])).slice(-2);
      var year = (dataObj["year"]);
      return day + "/" + mnth + "/" + year;
    }
    return "";
  }
  static convertDatePickerDateToUnixFormat(dataObj) {
    if (dataObj.hasOwnProperty("singleDate")) {
      var dateObj = dataObj['singleDate']['date']
      var day = ("0" + (dateObj["day"])).slice(-2);
      var mnth = ("0" + (dateObj["month"])).slice(-2);
      var year = (dateObj["year"]);
      return year + "-" + mnth + "-" + day;
    }
    return "";
  }
  static getDatePickerDateFormat(dateStr = "") {
    if (dateStr.length == 0) {
      dateStr = DateHelper.getDMY();
    }
    var dateSplit = dateStr.split("/");
    return { "day": parseInt(dateSplit[0]), "month": parseInt(dateSplit[1]), "year": parseInt(dateSplit[2]) }
  }
  static getDatePickerMYFormat(dateStr = "") {
    if (dateStr.length == 0) {
      dateStr = DateHelper.getDMY();
    }
    var dateSplit = dateStr.split("/");
    return { "month": parseInt(dateSplit[0]), "year": parseInt(dateSplit[1]) }
  }
  static previousDate(dt=1) {
    let prev_date = new Date();
    prev_date.setDate(prev_date.getDate() - dt);
    let date = new Date(prev_date)
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let day = ("0" + date.getDate()).slice(-2);
    return { year: date.getFullYear(), month: parseInt(month), day: parseInt(day) }
  }
  static futureDate() {
    let future_date = new Date();
    future_date.setDate(future_date.getDate() + 1);
    let date = new Date(future_date)
    let month = ("0" + (date.getMonth() + 1));
    let day = ("0" + date.getDate());
    return { year: date.getFullYear(), month: parseInt(month), day: parseInt(day) }
  }
  static getYears() {
    var start_year = 2022;
    var cur_date = new Date();
    var curr_year = cur_date.getFullYear()
    var years: any = []
    for (var index = start_year; index <= (curr_year + 1); index++) {
      years.push(index)
    }
    return years
  }
  static getDateDiff(start_date, end_date, format = 'dd/MM/yyyy') {
    var dt1 = DateHelper.getStringToDate(start_date, format);
    var dt2 = DateHelper.getStringToDate(end_date, format);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24))
  }
  static getStringToDate(_date, _format = 'dd/MM/yyyy') {
    var _delimiter = _format.includes('/') ? '/' : '-'
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var year = parseInt(dateItems[yearIndex]);
    // adjust for 2 digit year
    if (year < 100) { year += 2000; }
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(year, month, dateItems[dayIndex]);
    return formatedDate;
  }
  static getMonth(tdate = new Date()) {
    let month = tdate.getMonth() + 1;
    var filter_data = JSON.parse(JSON.stringify(ApiService.MONTH_NAMES)).filter((m) => m["id"] == month);
    if (filter_data.length > 0) {
      var tmpObj = filter_data[0];
      tmpObj["year"] = tdate.getFullYear();
      return filter_data[0];
    }
    return {};
  }
  static getWeekDates(month, year) {
    month = ("0" + month).slice(-2)
    var firstDate = new Date(`${year + '-' + month + '-01'}T00:00:00Z`)
    var lastDate = new Date(year, month, 0);
    return this.getDatesBetweenDates(
      this.startOfWeek(firstDate),
      this.startOfWeek(lastDate, false)
    );
  }
  static startOfWeek(date, isStart = true) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    if (!isStart) {
      diff = date.getDate() + (7 - (date.getDay() - 1));
      if (date.getDay() == 0) {
        diff = date.getDate() + 1;
      }
    }
    return new Date(date.setDate(diff));
  }
  static getDatesBetweenDates = (startDate, endDate, day_nums: any = []) => {
    let dates: any = [];
    //to avoid modifying the original date
    const theDate = new Date(startDate);
    while (theDate <= endDate) {
      var month = theDate.getMonth() + 1;
      let format_month = ("0" + month).slice(-2);
      let formatted_day = ("0" + theDate.getDate()).slice(-2);
      let unix_date =
        theDate.getFullYear() + "-" + format_month + "-" + formatted_day;
      const formatted_date_info = {
        day: theDate.getDay(),
        dayName: ApiService.SINGLELETTERWEEKDAYS[theDate.getDay()],
        fullDayName: ApiService.THREELETTERWEEKDAYS[theDate.getDay()],
        unix_date_format: unix_date,
        display_date_format: DateHelper.convertYMDToDMY(unix_date),
        format_day: formatted_day,
        month: month,
        format_month: format_month,
        year: theDate.getFullYear(),
      };
      if (day_nums.length > 0) {
        if (day_nums.includes(theDate.getDay())) {
          dates = [...dates, formatted_date_info];
        }
      } else {
        dates = [...dates, formatted_date_info];
      }
      theDate.setDate(theDate.getDate() + 1);
    }
    return dates;
  };

  static covertLongToShortDate(longDate, type) {
    const convertDate = new Date(longDate);
    const date = convertDate.getDate()
    const month = convertDate.getMonth() + 1
    const year = convertDate.getFullYear()
    if (type == 1) {
      var updatedDate = date + '/' + month + '/' + year
    } else {
      var updatedDate = year + '-' + month + '-' + date
    }
    return updatedDate;
  }

  static covertTimeToMinutes(fulltime, type) {
    if (type == 1) {
      const convertTime = timeStr => {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
          hours = '00';
        }
        if (modifier === 'PM') {
          hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
      };
      const tempTime = convertTime(fulltime).split(':')
      const hr = Number(tempTime[0])
      const min = Number(tempTime[1])
      const totalMinutes = (hr * 60) + min;
      return totalMinutes;
    } else if (type == 2) {
      const today = new Date();
      const totalMinutes = (today.getHours() * 60) + today.getMinutes();
      return totalMinutes;
    }
  }

  static convertDMYToDM(date) {
    const splitDate = date.split('/');
    const tempDate = splitDate[0];
    const tempMnth = splitDate[1];
    const requiredFormat = tempDate + '/' + tempMnth;
    return requiredFormat;
  }

  static toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours>0?hours+(hours==1?' Hour':' Hours'):''} ${minutes>0?minutes + ' Minutes':''}`;
  }
}