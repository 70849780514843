import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { NavController, Platform } from "@ionic/angular";
import {
  deep_link_hostname,
  new_deep_link_base_url,
  proffis_deep_link_base_url,
} from "src/environments/environment";
import { AlertHandlerService } from "../alert-handler.service";
import { ApiService } from "../api.service";
import { DataFetcherService } from "../data-fetcher/data-fetcher.service";
import { StorageService } from "../storage.service";
import { AppHelper } from "./app-helper";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class DeeplinkHelperService {
  EventSaveParams = {
    EventType: "",
    ObjectType: "",
    ObjectID: "",
    Path: "",
    PathType: "",
    SenderPID: "",
  };
  EventReceipientSaveParams = {
    AccessID: "",
    ReceipientPID: "",
  };
  deep_link_paths = {
    MerchantDetails: "/tabs/merchantdetails?MerchantID=",
    ProppyCaresClaim: "/proppycares-claim/",
    MemberProfile: "/tabs/memberprofile?MemberID=",
    PublicProfile: '/tabs/myqrcode?pid=',
    AddEndorsement: "/tabs/add-endorsement/",
    Registration: "/registration/",
    OwnBusiness: "/tabs/businesses-own/",
    AgentProperty: "/tabs/property-agent?AgentId=",
    PropertyDetails: "/tabs/property-details?ListingID=",
    ProppyAcademyDetails: "/tabs/proppy-academy-details?ListingID=",
    ProppyCareNeedsConfirmOrder:
      "/tabs/proppycare-needs-confirm-order?OrderID=",
    OrderReceipt: "/tabs/order-receipt?OrderID=",
    GemTransfer: "/tabs/gem-transfer?ReceiverProppyID=",
    ClaimRedPacket: "/tabs/red-packet/claim?SenderProppyID=",
    C360registration: "/c360/c360-registration/",
    SosDisplay: "/c360/c360-sosdisplay/",
    C360SuppliesDisplay: "/c360/c360-suppliesdisplay/",
    C360SupportDisplay: "/c360/c360-supportdisplay/",
    C360VaccineDisplay: "/c360/c360-vaccinedisplay/",
    AgencyProfile: "/agency-profile? AgencyName=",
    AddInvite: "/myunitprofile",
    DeveloperProfile: "/tabs/developer-gallery/developer-profile?developer_id=",
    DeveloperNewProject:
      "/tabs/developer-gallery/developer-new-project?listing_id=",
    ProductDetails: "/tabs/premium-merchant/product-details?product_id=",
    ServiceDetails: "/tabs/premium-merchant/service-details?service_id=",
    ServiceOrderTracker: "/tabs/premium-merchant/booking-tracker?order_hdr_id=",
    ProductOrderTracker: "/tabs/proppy-deal/pm-progress-tracker?order_hdr_id=",
    PMOrderReceipt: "/tabs/premium-merchant/receipt?order_hdr_id=",
    Preloved: "tabs/preloved/preloveddetails?ListingID=",
    Visitor: "/proppy-homes-v2/visitor-invitation/checkout-payment?visit_id=",
    // "Visitor": "tabs/myvisit-scanner?visitID=",
    VoucherDetails: "tabs/premium-merchant/voucher-details?disc_code=",
    VisitorSelfRegistration: "/tabs/proppy-homes-v2/visitor-self-registration?property_id=",
    BasicInfo: "/layout/ml-tabs/premium-merchant/basic-info?merchant_id=",
    PMActivation: "layout/ml-tabs/premium-merchant/premium-merchant-activation",
    MerchantShortcut: "/tabs/merchantdetails?merchant_id=",
    Signature: "/proppy-signature?doc_id=",
    ProppyChest: "/tabs/proppy-chest",
    PCardPersonnel: "/tabs/personal-pcard/personnel-card-show?MemberID=",
    WallPost: `/tabs/proppy-wpe/wall-post-details?post_id=`,
    WallEvent: `/tabs/proppy-wpe/wall-post-event-details?event_id=`,
    PromoterDetails: "/tabs/premium-merchant/pm-promoter/promo-code-details?promo_code=",
    PCardBusiness: `/tabs/business-card/pcard-biz-show?template_id=`,
    MyQRCode : '/tabs/myqrcode?pid=',
    EndorsedList: '/tabs/endorsedlist',
    OfflineLoyaltyRedemption: `/tabs/my-membership?merchant_id=`,
    UnPublishedMerchantStoreFront: "/tabs/merchantdetails?is_preview=1&MerchantID="
  };
  path_types = {
    MerchantDetails: "Public",
    ProppyCaresClaim: "Private",
    MemberProfile: "Private",
    PublicProfile: 'Public',
    AddEndorsement: "Private",
    Registration: "Public",
    OwnBusiness: "Private",
    AgentProperty: "Private",
    PropertyDetails: "Public",
    ProppyAcademyDetails: "Public",
    ProppyCareNeedsConfirmOrder: "Public",
    OrderReceipt: "Private",
    GemTransfer: "Private",
    ClaimRedPacket: "Private",
    C360registration: "Public",
    SosDisplay: "Public",
    C360SuppliesDisplay: "Public",
    C360SupportDisplay: "Public",
    C360VaccineDisplay: "Public",
    AgencyProfile: "Private",
    AddInvite: "Public",
    DeveloperProfile: "Public",
    DeveloperNewProject: "Public",
    ProductDetails: "Public",
    ServiceDetails: "Public",
    PMOrderReceipt: "Private",
    ProductOrderTracker: "Private",
    ServiceOrderTracker: "Private",
    Preloved: "Private",
    Visitor: "Private",
    VoucherDetails: "Public",
    VisitorSelfRegistration: "Public",
    BasicInfo: "Protected",
    PMActivation: "Protected",
    MerchantShortcut: "Private",
    Signature: "Private",
    ProppyChest: "Private",
    PCardPersonnel: "Public",
    WallPost: "Public",
    WallEvent: "Public",
    PromoterDetails: "Public",
    PCardBusiness : "Public",
    MyQRCode : "Public",
    EndorsedList: "Public",
    OfflineLoyaltyRedemption: "Public",
    UnPublishedMerchantStoreFront: "Public"
  };

  AppPublicObjectTypes = ["Registration"];
  constructor(
    private dataFetcherService: DataFetcherService,
    private alertHandelerService: AlertHandlerService,
    private storage: StorageService,
    private router: Router,
    private alertHandlerService: AlertHandlerService,
    private helper: AppHelper,
    private navCtrl: NavController,
    private plt: Platform,
    private iab: InAppBrowser,
    private routes: ActivatedRoute
  ) {}

  async createEvent(eventSaveParams, cb, hasLoading = 1) {
    if (hasLoading == 1) {
      await this.alertHandelerService.presentLoading();
    }
    this.dataFetcherService
      .doPost(ApiService.CREATE_DEEPLINK_EVENT, eventSaveParams)
      .subscribe(
        (res) => {
          if (hasLoading == 1) {
            this.alertHandelerService.dismissLoading();
          }
          const status = res["status"] || false;
          cb(status, res["AccessID"]);
        },
        (err) => {
          if (hasLoading == 1) {
            this.alertHandelerService.dismissLoading();
          }
          this.alertHandelerService.presenToast(ApiService.ERROR_TOAST_MSG);
        }
      );
  }

  async getEvent(AccessID, cb) {
    await this.alertHandelerService.presentLoading();
    this.dataFetcherService
      .doPost(ApiService.GET_DEEPLINK_EVENT_BY_ACCESSID, { AccessID: AccessID })
      .subscribe(
        (res) => {
          this.alertHandelerService.dismissLoading();
          const status = res["status"] || false;
          cb(status, res["data"]);
        },
        (err) => {
          this.alertHandelerService.dismissLoading();
          this.alertHandelerService.presenToast(ApiService.ERROR_TOAST_MSG);
        }
      );
  }

  async recordEventAccess(eventReceipientSaveParams) {
    // await this.alertHandelerService.presentLoading()
    this.dataFetcherService
      .doPost(
        ApiService.RECORD_DEEPLINK_EVENT_ACCESS,
        eventReceipientSaveParams
      )
      .subscribe(
        (res) => {
          // this.alertHandelerService.dismissLoading()
          const status = res["status"] || false;
          console.log(status, res);
        },
        (err) => {
          // this.alertHandelerService.dismissLoading()
          this.alertHandelerService.presenToast(ApiService.ERROR_TOAST_MSG);
        }
      );
  }

  processShareLink(
    objectType,
    objectID,
    cb,
    path_extension = objectID,
    EventType = "Share",
    hasLoading = 1
  ) {
    this.storage.getString("Proppy_UserID", (PID) => {
      this.createEvent(
        {
          EventType: EventType,
          ObjectType: objectType,
          ObjectID: objectID,
          Path: encodeURI(this.deep_link_paths[objectType] + path_extension),
          PathType: this.path_types[objectType],
          SenderPID: PID,
        },
        (status, accessID) => {
          if (status) {
            var url = new_deep_link_base_url;
            if (this.path_types[objectType] == "Protected") {
              url = proffis_deep_link_base_url;
            }
            cb(url + accessID);
          } else {
            this.alertHandelerService.presenToast(ApiService.ERROR_TOAST_MSG);
          }
        },
        hasLoading
      );
    });
  }

  onAccessCapture(AccessID, isPublic = false) {
    console.log("onAccessCapture...");
    this.getEvent(AccessID, (status, data) => {
      console.log(status, data);
      if (status) {
        this.storage.getString("Proppy_UserID", async (PID) => {
          if (PID.length > 0 && data["ObjectType"] == "Registration") {
            var senderPID = data["SenderPID"];
            if (senderPID != "PROPPY") {
              this.navCtrl.navigateForward(
                this.deep_link_paths["MemberProfile"] + senderPID
              );
              this.recordEventAccess({
                AccessID: AccessID,
                ReceipientPID: PID || "PROPPY-WEB",
              });
            }
            return;
          }
          if (PID.length > 0 && data['ObjectType'] == 'PublicProfile') {
            var senderPID = data['SenderPID']
            if (senderPID != 'PROPPY') {
              this.navCtrl.navigateForward(this.deep_link_paths['PublicProfile'] + senderPID)
              this.recordEventAccess({
                AccessID: AccessID,
                ReceipientPID: (PID || "PROPPY-WEB")
              })
            }
            return;
          }
          if (PID.length > 0 && data['ObjectType'] == 'VisitorSelfRegistration') {
            var visit_data = { "property_id": data["ObjectID"], "pid": PID, "access_id": AccessID, "is_public": isPublic, "event_data": data }
            this.addSelfRegistration(visit_data)
            return;
          }
          console.log("before");
          if (PID.length > 0 && data["ObjectType"] == "VisitorAttendance") {
            console.log("In");
            var attendance_data = {
              property_id: data["ObjectID"],
              pid: PID,
              access_id: AccessID,
              is_public: isPublic,
              event_data: data,
            };
            console.log(attendance_data, "attendance_data");
            this.storage.getData("User_Address_Obj", (userAddress) => {
              console.log(userAddress);
              if (userAddress) {
                attendance_data["lat"] = userAddress["lat"] || "";
                attendance_data["long"] = userAddress["long"] || "";
              }
              this.setAttendance(attendance_data);
            });
            return;
          }
          if( data["ObjectType"] == 'DynamicQr') {
            this.processDynamicQr(AccessID)
            return
          }
          console.log("out");
          setTimeout(() => {
            this.deepLinkNavigation(PID, isPublic, data, AccessID);
          },1000)
        });
      } else {
        this.alertHandlerService.presenToast(ApiService.ERROR_TOAST_MSG);
        this.navCtrl.navigateForward(["/tabs/tab1"]);
      }
    });
  }
  async deepLinkNavigation(PID, isPublic, data, AccessID) {
    if (ApiService.IS_SKIP_LOGIN == 1) { // SKIP LOGIN ACCESS REFERRER PID
      this.storage.setString('referrer_pid', data['SenderPID']);
    }
    // 0 indicates don't do anything
    let allowAsSkipLogin = 0;
    if (ApiService.IS_SKIP_LOGIN == 1 && !isPublic) {
      if ((data["PathType"] || "").toLowerCase() == "public") {
        // 1 indicates route to path in if condition
        allowAsSkipLogin = 1;
      } else {
        // 2 indicates route to login in else condition
        allowAsSkipLogin = 2;
      }
    }
    if (
      PID || allowAsSkipLogin == 1 ||
      (isPublic && (data["PathType"] || "").toLowerCase() == "public") ||
      (!isPublic && this.AppPublicObjectTypes.includes(data["ObjectType"]))
    ) {
      if (data["PathType"] == "Protected" && PID != data["SenderPID"]) {
        this.alertHandelerService.presenToast("Invalid Access. ");
        return;
      }
      if (isPublic && data["Path"].includes("tabs")) {
        data["Path"] = data["Path"].replace("tabs/", "");
        data["Path"] += "&SenderPID=" + data["SenderPID"];
      }
      let currentRoutePath = this.router.url.split("?")[0];
      let linkRoutePath = data["Path"].split("?")[0];
      if (
        currentRoutePath == linkRoutePath &&
        ApiService.deeplinkCallBack != null
      ) {
        ApiService.deeplinkCallBack(data);
      } else {
        console.log(data["Path"]);
        this.navCtrl.navigateForward(data["Path"]);
      }
      this.recordEventAccess({
        AccessID: AccessID,
        ReceipientPID: PID || "PROPPY-WEB",
      });
    } else {
      await this.storage.setString("AccessID", AccessID);
      if (
        !PID &&
        !this.helper.isDesktop() &&
        data["ObjectType"] == "MemberProfile"
      ) {
        this.navCtrl.navigateForward(["/registration/" + data["SenderPID"]]);
        return;
      }
      if (allowAsSkipLogin == 2) {
        this.router.navigateByUrl('/login');
        return;
      }
      this.navCtrl.navigateForward([
        isPublic && data["PathType"] == "Private"
          ? "/registration/" + data["SenderPID"]
          : "/login",
      ]);
    }
  }

  onLoginAccessIDCheck(PID) {
    this.storage.getString("AccessID", (AccessID) => {
      if (AccessID.length > 0) {
        this.getEvent(AccessID, (status, data) => {
          if (status) {
            this.router.navigateByUrl(data["Path"], {
              replaceUrl: true,
            });
            this.storage.removeItem("AccessID");
            this.recordEventAccess({
              AccessID: AccessID,
              ReceipientPID: PID,
            });
          } else {
            this.alertHandlerService.presenToast(ApiService.ERROR_TOAST_MSG);
            ApiService.IS_APP_POPUP_SHOW = 1;
            this.router.navigate(["/tabs/tab1"], {
              replaceUrl: true,
            });
          }
        });
      } else {
        ApiService.IS_APP_POPUP_SHOW = 1;
        this.router.navigate(["/tabs/tab1"], {
          replaceUrl: true,
        });
      }
    });
  }

  processQrContent(qrText) {
    console.log("236", qrText);
    var bytes = CryptoJS.AES.decrypt(qrText, ApiService.ENCRYPT_KEY);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    const decryptedData = JSON.parse(originalText || '{}');
    if (
      "qr_token" in decryptedData &&
      "objectType" in decryptedData &&
      decryptedData?.["objectType"] == "ProffisQrLogin"
    ) {
      this.navCtrl.navigateForward(['/tabs/tab1']);
      this.proffisLoginQRCodeScanned(decryptedData);
    } else if (this.isValidAppLink(qrText)) {
      let applink = new URL(qrText);
      if (applink.toString().includes("AccessID")) {
        var AccessID = applink.toString().split("=")[1] || "";
        AccessID = AccessID.split("&")[0];
        this.onAccessCapture(AccessID);
        return;
      }
      let pathname = applink.pathname.replace(/^\/+/g, "");
      let approute = pathname.split("/");
      // Create our internal Router path by hand
      let internalPath = "";
      if (approute[0]) {
        internalPath = "/" + approute[0];
      }
      if (approute[1]) {
        internalPath = internalPath + "/" + approute[1];
      }

      // Run the navigation in the Angular zone
      if (internalPath != "") {
        this.router.navigateByUrl(internalPath);
      }
    }
    //valid link that's not our app
    else if (this.isValidLink(qrText)) {
      //open the link in inAppBrowser
      this.openAppstoreEntry(qrText);
    }
    //any other type of data that we cannot handle atm
    else {
      this.alertHandelerService.presenToast("Invalid QR Code. Please Try Again.")
      console.log('invalid data: ' + qrText);
    }
  }

  setAttendance(attendance_data) {
    console.log(attendance_data, "ser in");
    var params = {
      pid: attendance_data["pid"],
      property_id: attendance_data["property_id"],
      unit_id: null,
      latitude: attendance_data["lat"],
      longitude: attendance_data["long"],
    };
    console.log("finally");
    this.dataFetcherService.doPost(ApiService.ADD_ATTENDANCE, params).subscribe(
      (res) => {
        const status = res["status"] || false;
        if (status) {
          this.alertHandelerService.presenToast(
            "Attendance Status Updated Successfully."
          );
        } else {
          this.alertHandelerService.presenToast(
            res["msg"] || ApiService.ERROR_TOAST_MSG
          );
        }
      },
      (error) => {
        this.alertHandelerService.presenToast(
          error["error"] || ApiService.ERROR_TOAST_MSG
        );
      }
    );
  }

  isValidAppLink(string) {
    try {
      let url = new URL(string);
      return (
        url.protocol === "proppyapp:" ||
        (url.protocol === "https:" && url.hostname === deep_link_hostname)
      );
    } catch (error) {
      console.log("Error", error);
      return false;
    }
  }

  isValidLink(string) {
    try {
      let url = new URL(string);
      return true;
    } catch (error) {
      console.log("Error", error);
      return false;
    }
  }

  openAppstoreEntry(url = "") {
    const isAndroid = this.plt.is("android");
    //console.log("open:" + url);
    if (isAndroid) {
      window.open(url, "_system");
    } else {
      this.iab.create(url, "_system");
      // this.iab.open('http://whitelisted-url.com', '_system');
      // window.open(url, "_system");
    }
  }

  processQrContentAsMemberInfo(qrText, cb) {
    let applink = new URL(qrText);
    if (this.isValidAppLink(qrText)) {
      if (applink.toString().includes('AccessID')) {
        var AccessID = applink.toString().split('=')[1] || ''
        AccessID = AccessID.split('&')[0]
        this.getEvent(AccessID, (Status, res) => {
          if (Status && (res['ObjectType'] == 'MemberProfile' || res['ObjectType'] == 'Registration' || res['ObjectType'] == 'PropertyDetails' || res['ObjectType'] == 'PublicProfile')) {
            cb(res['ObjectID'])
          } else {
            this.alertHandelerService.presenToast("Invalid QR Code.");
          }
        });
        return;
      }
      var link_path = applink.pathname.split("/");
      if (link_path[1] == "memberprofile") {
        if (applink.search) {
          var AccessID = applink.search.split("=")[1] || "";
          AccessID = AccessID.split("&")[0];
          cb(AccessID);
        } else {
          cb(link_path[2]);
        }
        return;
      }
    } else if (this.isValidLink(qrText)) {
      var link_path = applink.pathname.split("/");
      if (link_path[1] == "memberprofile") {
        if (applink.search) {
          var AccessID = applink.search.split("=")[1] || "";
          AccessID = AccessID.split("&")[0];
          cb(AccessID);
        } else {
          cb(link_path[2]);
        }
        return;
      }
    } else if (qrText.startsWith("AA")) {
      cb(qrText);
    }
    this.alertHandelerService.presenToast("Invalid QR Code.");
  }
  async addSelfRegistration(postParams) {
    this.storage.getString("user", (user_data) => {
      this.dataFetcherService
        .doPost(ApiService.GET_PH_PROPERTY_USER_DETAILS, {
          pid: postParams["pid"],
          property_id: postParams["property_id"],
        })
        .subscribe((res) => {
          const status = res["status"] || false;
          if (status) {
            const data = res["data"] || [];
            let data_json = {
              name: "",
              property_id: postParams["property_id"],
              visitor_name: user_data["Proppy_Name"] || "",
              visitor_pid: postParams["pid"],
              visitor_mobile_no: user_data["Proppy_Mobile"] || "",
              unit_id: "",
              purpose_of_visit: "",
              visit_type: 3,
              vehicle_type_reg_no: "",
              vehicle_brand_name: "",
              vehicle_brand_model: "",
              vehicle_type: 0,
            };
            if (data.length > 0) {
              const unitInfo = data[0] || {};
              const vehicleInfo = unitInfo["vehicle_info"] || {};
              let vehicle_types = [
                { type: 1, name: "Car", img: "car" },
                { type: 2, name: "Motorcycle", img: "motor_cycle" },
                { type: 3, name: "Suv", img: "suv" },
                { type: 4, name: "Van", img: "van" },
                { type: 5, name: "Lorry", img: "lorry" },
              ];
              data_json = {
                name: unitInfo["unit_name"],
                property_id: unitInfo["property_id"],
                visitor_name: unitInfo["name"],
                visitor_pid: postParams["pid"],
                visitor_mobile_no: unitInfo["tele_phone"],
                unit_id: unitInfo["unit_id"],
                purpose_of_visit: unitInfo["type"],
                visit_type: 1,
                vehicle_type_reg_no: vehicleInfo["reg_no"] || "",
                vehicle_brand_name: vehicleInfo["vehicle_brand"] || "",
                vehicle_brand_model: vehicleInfo["vehicle_model"] || "",
                vehicle_type: 0,
              };
              if ((vehicleInfo["vehicle_type"] || "").length > 0) {
                var tmpVhArr = vehicle_types.filter(
                  (f) =>
                    f["name"].toLocaleLowerCase() ==
                    vehicleInfo["vehicle_type"].toLocaleLowerCase()
                );
                if (tmpVhArr.length > 0) {
                  data_json["vehicle_type"] = tmpVhArr[0]["type"] || 0;
                }
              }
            }
            this.dataFetcherService
              .doPost(ApiService.ADD_SELF_REGISTRATION, {
                property_id: postParams["property_id"],
                data_json: JSON.stringify(data_json),
                pid: postParams["pid"],
              })
              .subscribe(
                (res) => {
                  const status = res["status"] || false;
                  if (status) {
                    this.alertHandelerService.presenToast(
                      "Request Sent To Security Guard"
                    );
                  } else {
                    this.alertHandelerService.presenToast(
                      res["msg"] || ApiService.ERROR_TOAST_MSG
                    );
                  }
                },
                (err) => {
                  this.alertHandelerService.presenToast(
                    err["error"] || ApiService.ERROR_TOAST_MSG
                  );
                }
              );
          } else {
            this.deepLinkNavigation(
              postParams["pid"],
              postParams["is_public"],
              postParams["event_data"],
              postParams["access_id"]
            );
          }
        });
    });
  }


  proffisLoginQRCodeScanned(data: any) {
    let qrTokenTime = new Date(data["token_expiry_date"]).getTime();
    let curTime = new Date().getTime();
    if (qrTokenTime < curTime) {
      this.alertHandelerService.presenToast('Invalid QR Code. Please Try Again.');
    } else {
      var qr_data = {
        objectType: data["objectType"],
        app_id: Number(data["app_id"]),
        qr_token: data["qr_token"],
        login_status: Number(data["login_status"]),
        token_expiry_date: data["token_expiry_date"],
      };
      this.storage.getString("Proppy_UserID", (PID) => {
        if (PID) qr_data["pid"] = PID;
        this.storage.getData("User_Address_Obj", (userAddress) => {
          console.log(userAddress);
          if (userAddress) {
            qr_data["latitude"] = userAddress["lat"] || "";
            qr_data["longitude"] = userAddress["long"] || "";
          }
          this.dataFetcherService.doPost(ApiService.Qr_LOGIN_SCAN, qr_data).subscribe({
            next: (res: any) => {
              console.log(res);
              const status = res['status'] || false;
              if (status) {
                console.log("Success!");
              }
            },
            error: (e: any) => {
              this.alertHandelerService.presenToast('Invalid QR Code. Please Try Again.');
            },
            complete: () => { },
          });
        });
      });
    }
  }

  processDynamicQr(accessID : any) {
    this.dataFetcherService.doPost(ApiService.GET_QR_LINK_DETAILS, {from_pro_event_access_id : accessID}).subscribe({
      next: (res: any) => {
        const status = res['status'] || false;
        if (status) {
          const data = res['data'] || []
          const qrLink = data[0] || {}
          const redirectAccessID = qrLink['redirect_pro_event_access_id'];
          this.onAccessCapture(redirectAccessID,this.helper.isDesktop());
        } else {
          this.alertHandelerService.presenToast('Qr Code No Longer Active.');
        }
      },
      error: (e: any) => {
        this.alertHandelerService.presenToast('Invalid QR Code. Please Try Again.');
      },
      complete: () => { },
    });
  }
}
